$fa-font-path: "../webfonts";

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/tooltip";

@import "../../node_modules/bootstrap/scss/helpers";
@import "../../node_modules/bootstrap/scss/utilities/api";

@import "./fontawesome/fontawesome";
@import "./fontawesome/solid";
@import "./fontawesome/brands";

.navbar {
  background-color: rgb(9, 163, 136);
}

.navbar .navbar-brand {
  color: #fff;
}

.breadcrumb .breadcrumb-item {
  font-size: 1.5em;
}

.breadcrumb .breadcrumb-item a {
  text-decoration: none;
  color: rgb(9, 163, 136);
}

.table-responsive {
  height: calc(100vh - 56px - 36px - 41px - 61px);
  overflow-y: auto;
}

.table thead {
  background: #fff;
}

.table i {
  color: #4d5154;
}

.table thead:after {
  content: "";
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 2px;
  background-color: #373b3e;
}

.table tbody {
  border-top: none !important;
}

.table a {
  color: #373b3e;
  text-decoration: none;
}

.table {
  --bs-table-hover-bg: rgba(0, 0, 0, 0.15);
}

.file-count {
  font-size: 1.2em;
}

@media (prefers-color-scheme: dark) {
  body {
    background: #131516;
  }

  .navbar {
    background-color: rgb(7, 130, 109);
  }

  .breadcrumb .breadcrumb-item a {
    color: rgb(93, 246, 219);
  }

  .breadcrumb .breadcrumb-item.active {
    color: rgb(158, 150, 137) !important;
  }

  .table {
    color: rgb(209, 205, 199);
    border-color: rgb(56, 61, 63);
  }

  .table thead {
    background: #131516;
  }

  .table thead:after {
    content: "";
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: rgb(209, 205, 199);
  }

  .table i {
    color: rgb(209, 205, 199);
  }

  .table td,
  table th {
    color: rgb(209, 205, 199) !important;
  }

  .table a {
    color: rgb(209, 205, 199);
  }

  .table {
    --bs-table-hover-bg: rgba(90, 90, 90, 0.075);
  }

  .file-count {
    color: rgb(209, 205, 199);
  }
}
